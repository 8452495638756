// * 一樣使用 bootstrap 的 mixins & variables
@import 'custom-variables';
@import "~bootstrap/scss/functions";
@import "./bs-variables";
@import "~bootstrap/scss/mixins";

// * home

.index_roomTour{
    .card-title{
        min-height: 105px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.index_about__content{
    .row .col-12,
    .row .col-12{
        flex: 0 0 100%;
        max-width: 100%;
    }
    h2.text-center{
        text-align: left!important; // QQQ
    }
}

.rent_header{
    .title--center{
        font-size: 3rem;
        @include media-breakpoint-up(md) {
            font-size: 4rem;
        }
    }
    .rent_header__btnWrap{
        flex-wrap: wrap;
        button{
            @include media-breakpoint-up(md) {
                font-size: 1.25rem;
            }
            @include media-breakpoint-up(lg) {
                flex: 0 0 50%;
            }
        }
    }
}

// * rent & equipment 頁面底部的大字區塊
.rent_onePrice,
.equipment_benefit{
    .row {
        .col-md-4{
            @include media-breakpoint-up(md) {
                flex: 0 0 40%;
                max-width: 40%;
            }
        }
        .col-md-8{
            @include media-breakpoint-up(md) {
                flex: 0 0 60%;
                max-width: 60%;
            }
        }
    }
    .title--hugeWrap .text-huge{
        font-size: 40px;
        @include media-breakpoint-up(md) {
            font-size: 40px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 50px;
        }
    }
    h2.justify-content-center{
        text-align: left;
    }
}